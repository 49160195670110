<template>
    <div class="grid crud-demo">
        <Dialog v-model:visible="editInvoice" :closable="true" :modal="true"  :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '65vw'}" :maximizable="true">
            <LinkPagoUI :id="editCard.id" @updated="showLinkPago" @deleted="showLinkPago" @selected="showLinkPago" />
        </Dialog>
        <Toast />
        <Loader v-model="loading" />

            <div class="card">
                <Toast />
                <Panel header="Administrador">
                    <BasicFormToolbar  @refresh="refresh" @list="list" :actions="[ 'list', 'refresh']" />
                    <Fieldset :toggleable="true" legend="Parametros">
                        <div class="p-fluid p-formgrid grid">


                            <div class="field col-4">
                                <label>Fecha Inicial</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.initial_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.initial_date === false }" />
                                <small class="p-invalid" v-if="validate.validations.initial_date === false"> Favor de llenar el campo </small>
                            </div>
                            <div class="field col-4">
                                <label>Fecha Final</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.final_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.final_date === false }" />
                                <small class="p-invalid" v-if="validate.validations.final_date === false"> Favor de llenar el campo </small>
                            </div>
                        </div>
                    </Fieldset>
                    <br />
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12">
                            <Fieldset :toggleable="true" legend="Resultados">
                                <BasicDatatable
                                :menuModel="[

                             
                                    {label: 'Borrar Token', icon: 'pi pi-times-circle', action: 'deleteToken'},

                                ]"
                            @deleteToken="deleteToken"
                                :contextMenu="true" :rowaction="true"  :headers="headers" :rows="entities" />
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>

    </div>
</template>
<script>
//import FormDropdown from "../../../components/general/FormDropdown.vue";
import { PaymentParameters } from '../../../models/custom/business_wallet/PaymentParameters';
import { BWCustomer } from '../../../models/custom/business_wallet/BWCustomer';
import Session from '../../../mixins/sessionMixin';
import { Rule, ErrorToast, validate, fillObject, HeaderGrid, Toast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import LinkPagoUI from './LinkPago.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { UniverseTransaction } from '../../../models/custom/business_wallet/BWUniverseTransaction';

export default {
    mixins: [Session],
    data() {
        return {
            editInvoice: false,
            editCard: new PaymentParameters(this.session),
            entity: {
                id:"0",
                id_customer: 0,
                initial_date: new Date(),
                final_date: new Date(),
            },
            entities: [],
            tags: [],
            customers: [],
            //   companyDB:[],
            filters: null,
            loading: false,
            loadingInputs: false,
                //   company_val:null,
                //   companydb_val:null,

            headers: [
                      new HeaderGrid('ID Ultima Transaccion','id'),
                new HeaderGrid('Nombre del Cliente','names'),
                 new HeaderGrid('Tarjeta','card'),
                new HeaderGrid('Tipo de Tarjeta','type'),

                  new HeaderGrid('Numero Tarjeta', 'trxCard', {img: true, img_field: 'trxCard', img_ext: 'png'}),
                // new HeaderGrid('Token Direccion','id_bw_cybersource_customer_shipping_address'),
                // new HeaderGrid('Token Instrumento de pago','id_bw_cybersource_customer_payment_instrument'),
                new HeaderGrid('Fecha de Creacion','fechacreated', { type: 'date'}),
                  new HeaderGrid('Fecha de Ultima Transaccion','created2', { type: 'date'}),
                   new HeaderGrid('Dias desde la Creacion','dias_created'),
                  new HeaderGrid('Dias desde la ultima Transaccion','dias_created2' )
           

            ],
            rules: [new Rule({ name: 'initial_date' }), new Rule({ name: 'final_date' })],
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null,
                },
            },
        };
    },
    components: { Loader, LinkPagoUI,BasicFormToolbar, BasicDatatable },
    created() {
        this.initFilters();

    },

    async mounted() {
        await this.refresh();
          this.companyDB= await new UniverseTransaction(this.session).companyDB()
    },
    methods: {
        copy(payload) {
            if (payload.paymentLink) {
                navigator.clipboard.writeText(payload.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: "Link de Pago",
                        detail: "Se ha copiado el link en el portapapeles",
                        })
                );
            }else {
                this.$toast.add(new ErrorToast("No se ha generado el URL"));
            }
        },
       async  deleteToken(entities){
           console.log(entities.id_bw_cybersource_customer)


                this.entity=  await new BWCustomer(this.session)
//                 console.log(this.session)
//   this.entity.id=entities.id_bw_cybersource_customer
//     var company_val=entities.id_company;
//   var branch_val=entities.id_branch
//        await this.entity.deleteCustomCliente(company_val,branch_val);

        },


        whatsapp(payload) {
            if (payload.paymentLink) {
                window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(payload.paymentLink));
            }else {
                this.$toast.add(new ErrorToast("No se ha generado el URL"));
            }
        },
        // async send(payload) {
        //     this.loading = true;
        //     try {
        //         let invoice = new BWPaymentInstrument(this.session);
        //         invoice = fillObject(invoice, payload);
        //         let response = await invoice.send();
        //         let index = this.entities.findIndex(x => x.id == response.id);
        //         this.entities[index].paymentLink = response.paymentLink;
        //         this.$toast.add(
        //             new Toast({
        //                 summary: "Link de Pago",
        //                 detail: "Se ha enviado correctamente el correo",
        //             })
        //         );
        //     } catch (error) {
        //         this.$toast.add(new ErrorToast(error));
        //     } finally {
        //         this.loading = false;
        //     }
        // },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
        },

        formatDate(value) {
            return new Date(value).toLocaleDateString('es-ES');
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        showLinkPago(data) {
            this.editCard.id = data.id;
            this.editInvoice = true;
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_descrption: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                paymentLink: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_dueDate: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                Estatus: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
            };
        },
        async list() {
            this.loading = true;
            this.editInvoice = false;
            try {
                //validacion de campos
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';
                var date2 = this.entity.final_date;

                const formatDate2 = (date2) => {
                    let formatted_date2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate() + ' ' + '23:59:59';
                    return formatted_date2;
                };

                this.entities = await new PaymentParameters(this.session).getTokenlist(
                formatDate2(this.entity.initial_date),
                formatDate2(date2),
                ''
                );
                console.log(this.entities)


            } catch (error) {
                this.$toast.add(new ErrorToast("La Base de datos necesaria para hacer la consulta no contiene las tablas necesarias para ejecutar la consulta"));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                (this.entity = {
                    id_customer: 0,
                    initial_date: new Date(),
                    final_date: new Date(),
                })
                    // await this.list();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
    .p-chip.custom-chip {
        background: var(--primary-color);
        color: var(--primary-color-text);
    }
    .p-chip.tag-chip {
        background: #2c9663;
        color: var(--primary-color-text);
    }
}
</style>